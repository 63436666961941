import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import loadable from '@loadable/component'
import Seo from '../../components/Seo/Seo'
import './NeighborhoodGuide.scss'

import next from '../../assets/images/next.png'
import back from '../../assets/images/back.png'

import { PropertyDataTypes } from '../Amenities/Amenities'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const Modal = loadable(() => import('../../components/Modal/Modal'))

type LocationPropTypes = {
  data: {
    propertyData: PropertyDataTypes
    allProperty: { nodes: PropertyDataTypes[] }
  }
}

const NeighborhoodGuide = ({
  data: {
    propertyData,
    allProperty: { nodes: propertyList },
  },
}: LocationPropTypes): ReactElement => {
  const [metaDesc, setMetaDesc] = useState<string>('')
  const [city] = useState(
    propertyData.towers[0]?.locationLandmarks?.locationBrief,
  )
  const [area] = useState(propertyData.towers[0]?.locationLandmarks?.region)
  const [locationDescription] = useState(
    propertyData?.towers[0]?.locationLandmarks?.locationDefault,
  )
  const [isLocationDetailsShowing, setIsLocationDetailsShowing] = useState<
    boolean
  >(false)

  const [projects] = useState(
    propertyList.filter(
      (proj) => proj.towers[0]?.locationLandmarks?.locationBrief,
    ),
  )
  const [nearbyProjects] = useState(
    projects.filter(
      (prop) =>
        prop.towers[0]?.locationLandmarks?.locationBrief === city &&
        prop.propertyType !== 'Estate',
    ),
  )

  const [displayFeaturedImages] = useState<string[]>(
    propertyData.towers[0]?.locationLandmarks?.locationImages?.map(
      (image) => image.url,
    ) || [],
  )

  const displayLandmarkEstablishment = propertyData.towers[0]?.locationLandmarks
    ?.landmarksEstablishment?.items
    ? propertyData.towers[0]?.locationLandmarks?.landmarksEstablishment?.items?.map(
        (item) => item,
      )
    : null

  const sliderRef = useRef<Slider>(null)

  const nextImage = useCallback(() => {
    if (sliderRef?.current?.slickNext) {
      sliderRef.current.slickNext()
    }
  }, [sliderRef])

  const prevImage = useCallback(() => {
    if (sliderRef?.current?.slickPrev) {
      sliderRef.current.slickPrev()
    }
  }, [sliderRef])

  const description = locationDescription || ''

  const handlePropertyType = useCallback((propertyType) => {
    if (propertyType === 'House and Lot') {
      return 'house-lot'
    }
    if (propertyType === 'Condominium') {
      return 'condominium'
    }
    return 'lots'
  }, [])

  const stripHtml = (html: string): string => {
    const text = html?.replace(/&nbsp;/g, '')
    const doc = new DOMParser().parseFromString(text, 'text/html')
    return doc.body.textContent || ''
  }

  useEffect(() => {
    if (typeof window === 'undefined' || !window.document) {
      return
    }
    setMetaDesc(stripHtml(description))
  }, [description])

  return (
    <Layout>
      <Seo
        title={`${city} Neighborhood Guide | Amaia Land | Affordable House and Lot & Condos in the Philippines`}
        ogMetaData={{
          description: metaDesc,
        }}
      />

      <div className="guide">
        <div className="guide-content">
          <div className="guide-content-info">
            <h1 className="title">{city} Neighborhood Guide</h1>
            <h4 className="title-area">{area}</h4>
            <p
              className="content"
              dangerouslySetInnerHTML={{
                __html:
                  description?.length >= 570
                    ? `${description?.substring(0, 570)}...`
                    : description,
              }}
            />
            <p
              className="mobile-content"
              dangerouslySetInnerHTML={{
                __html: description || '',
              }}
            />
            {description?.length >= 570 ? (
              <button
                type="button"
                onClick={(): void => setIsLocationDetailsShowing(true)}
              >
                Read More
              </button>
            ) : null}
          </div>
          <div className="selected-property-image-slider">
            <Slider
              swipeToSlide={false}
              arrows={false}
              dots={false}
              fade
              easing="ease"
              className="banner-slider"
              ref={sliderRef}
            >
              {displayLandmarkEstablishment
                ? displayLandmarkEstablishment.map((item) => (
                    <div key={item.establishmentName}>
                      <LazyLoadImage
                        key={item.establishmentName}
                        className="slider-image"
                        src={
                          item.imagesGallery ? item.imagesGallery[0].url : ''
                        }
                        alt=""
                      />
                      <p className="landmark">{item.establishmentName}</p>
                    </div>
                  ))
                : ''}
            </Slider>

            <div className="nav-button-container">
              <button
                type="button"
                className="nav"
                onClick={(): void => prevImage()}
              >
                <img src={back} alt="backIcon" />
              </button>
              <button
                type="button"
                className="nav"
                onClick={(): void => nextImage()}
              >
                <img src={next} alt="nextIcon" />
              </button>
            </div>
          </div>
        </div>

        <Modal
          className="left"
          side
          noIcons
          isShowing={isLocationDetailsShowing}
          onClose={(): void => setIsLocationDetailsShowing(false)}
        >
          <div className="details">
            <h1 className="title">{city} Neighborhood Guide</h1>
            <h4 className="title-area">{area}</h4>
            <LazyLoadImage
              src={displayFeaturedImages[0]}
              alt="property-banner"
              className="selected-property-location-details-banner"
            />
            <p
              className="description"
              dangerouslySetInnerHTML={{
                __html: locationDescription || '',
              }}
            />
          </div>
        </Modal>

        <div className="projects">
          <h3>Projects in {city}</h3>
          <div className="projects-content">
            {nearbyProjects.map((project) => {
              return (
                <div
                  key={`property${project?.id}`}
                  className="projects-content-info"
                >
                  <LazyLoadImage
                    src={
                      project.towers[0]?.overviewDetails?.imagesGallery?.find(
                        (unit) => unit.featured,
                      )?.url
                    }
                    alt="projectImg"
                  />
                  <Link
                    to={`/${handlePropertyType(project.propertyType)}/${
                      project.location
                    }/${project.slug}/`}
                    className="link"
                  >
                    <button type="button">{project.name}</button>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NeighborhoodGuide

export const pageQuery = graphql`
  query NeighborhooodQuery($id: String!) {
    propertyData: property(id: { eq: $id }) {
      ...PropertyPageFields
    }
    allProperty {
      nodes {
        ...OurPropertyPagePropertyFields
      }
    }
  }
`
